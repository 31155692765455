/* eslint no-console:0 */
import Rails from '@rails/ujs';

import '@/shared/components/accordion.js';
import '@/shared/components/checkbox_for_button.js';
import '@/shared/components/dropdown.js';
import '@/shared/components/burger_menu.js';
import '@/shared/components/modal.js';
import '@/shared/slider.js';
import '@/shared/klaro-config.js';
import '@/shared/stimulus.js';
import '../ecs/date_input_select.js';
import '../ecs/loader.js';
import '../ecs/login.js';
import '../ecs/password_field.js';
import '../ecs/file-downloader.js';
import '../ecppp/account.js';
import '../ecppp/parrainage.js';
import '../ecppp/site_selector.js';
import '../ecppp/mon_suivi_conso.js';
import '../ecppp/resiliation.js';
import '../ecppp/tracking_events.js';

Rails.start();
