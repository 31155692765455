import onLoadAsync from '@/shared/on_load_async.js';

onLoadAsync(() => {
  /* eslint-disable no-use-before-define */
  function propagateChosenDate() {
    if (resiliationForm?.checkValidity()) {
      const chosenDate = resiliationDate.value;
      confirmationDate.innerHTML = new Date(chosenDate).toLocaleDateString('fr-FR');
    }
  }

  function handleResiliationModal() {
    if (resiliationForm?.checkValidity()) {
      const modalName = modalTrigger?.dataset.resiliationModal;
      // eslint-disable-next-line no-undef, no-unused-expressions
      modalName && MicroModal.show(modalName);
    } else {
      resiliationForm?.reportValidity();
    }
  }
  const resiliationForm = document.querySelector('form#resiliation_form');
  const resiliationDate = resiliationForm?.querySelector('#resiliation_date');
  const modalTrigger = resiliationForm?.querySelector('[data-resiliation-modal]');
  const confirmationDate = document.querySelector('#modal-resiliation_confirmation .confirmation-date');
  modalTrigger?.addEventListener('click', handleResiliationModal);
  resiliationDate?.addEventListener('change', propagateChosenDate);
  propagateChosenDate();
});
